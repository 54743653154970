import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import { AiOutlineLoading } from "react-icons/ai";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer,
    PayPalHostedFieldsProvider,
    PayPalHostedField,
    usePayPalHostedFields,
} from "@paypal/react-paypal-js";
import { VscRefresh } from "react-icons/vsc";
import { MdCancelPresentation } from "react-icons/md";
import { CartContext } from "@components/contexts/CartContext";
import styles from "@components/sections/cesta/Styles";
import useSound from "use-sound";
import purchaseErrorSfx from "@static/sounds/purchaseError.mp3";
import purchaseSuccessSfx from "@static/sounds/purchaseSuccess.mp3";

const PaymentStep = ({ state, setState }) => {
    return (
        <>
            <div className={`grid grid-cols-1`}>
                {state.checkout && state.paymentMethod === "paypal" && (
                    <PaypalPayment state={state} setState={setState} />
                )}

                {state.checkout && state.paymentMethod === "card" && (
                    <CreditCardPayment state={state} setState={setState} />
                )}
            </div>
        </>
    );
};

const PaypalPayment = ({ state, setState }) => {
    const [loading, setLoading] = useState(false);
    const { cartItems, clearCart } = useContext(CartContext);
    const [playPurchaseError] = useSound(purchaseErrorSfx, { volume: 0.05 });
    const [playPurchaseSuccess] = useSound(purchaseSuccessSfx, {
        volume: 0.05,
    });

    // Metemos algún efecto de loading
    useEffect(() => {
        // console.log("Loading", loading);
    }, [loading]);

    const createOrder = async () => {
        setLoading(true);

        try {
            const json = await axios.post(
                "/.netlify/functions/payment-paypal-createorder",
                JSON.stringify({
                    user: state.user,
                    amount: state.amount,
                    discount: state.discount,
                    items: cartItems?.map(({ id, quantity }) => {
                        return {
                            id,
                            quantity,
                        };
                    }),
                }),
            );

            // Actualizamos estado
            setState(prevState => {
                return {
                    ...prevState,
                    order: {
                        ...prevState.order,
                        orderId: json.data.orderId,
                    },
                };
            });

            return json.data.orderId;
        } catch (err) {
            console.log(err?.message);

            setState(prevState => {
                return {
                    ...prevState,
                    payment: {
                        result: "fail",
                        json: err,
                        message: `El proceso de compra ha fallado. ${err?.message}`,
                    },
                };
            });
        }

        setLoading(false);
    };

    // data, actions
    const onApprove = async data => {
        setLoading(true);

        try {
            const json = await axios.post(
                "/.netlify/functions/payment-paypal-captureorder",
                JSON.stringify({
                    orderId: data.orderID,
                }),
            );

            if (json?.data?.success) {
                // Limpiamos el carrito
                clearCart();

                playPurchaseSuccess();

                // Actualizamos el estado global
                setState(prevState => {
                    return {
                        ...prevState,
                        amount: {
                            subtotal: 0,
                            shipping: 0,
                            discount: 0,
                            tax: 0,
                            total: 0,
                            units: 0,
                        },
                        discount: null,
                        checkout: false,
                        payment: {
                            result: "success",
                            message: `El proceso de compra se ha realizado correctamente. En breve recibirá más información a través del correo electrónico. Referencia de la orden: ${json?.data?.orderId}.`,
                            json: data,
                        },
                    };
                });
            } else {
                playPurchaseError();

                setState(prevState => {
                    return {
                        ...prevState,
                        payment: {
                            result: "fail",
                            json: data,
                            message: `El proceso de compra ha fallado. ${data}`,
                        },
                    };
                });
            }
        } catch (err) {
            console.log(err?.message);

            setState(prevState => {
                return {
                    ...prevState,
                    payment: {
                        result: "fail",
                        json: err,
                        message: `El proceso de compra ha fallado. ${err?.message}`,
                    },
                };
            });
        }

        setLoading(false);
    };

    const onError = data => {
        playPurchaseError();
        setState(prevState => {
            return {
                ...prevState,
                payment: {
                    result: "fail",
                    message: `El proceso ha fallado. ${data}`,
                    json: null,
                },
            };
        });
    };

    const onCancel = () => {
        playPurchaseError();
        setState(prevState => {
            return {
                ...prevState,
                payment: {
                    result: "warning",
                    json: null,
                    message: "El proceso de pago ha sido cancelado por el usuario.",
                },
            };
        });
    };

    // Efecto de loading mientras se carga PayPalScriptProvider
    const Loading = () => {
        const [{ isPending }] = usePayPalScriptReducer();

        return isPending ? (
            <VscRefresh className="w-8 h-8 animate-spin duration-300 text-gray-500 mb-5" />
        ) : null;
    };

    let amount = state.amount.total.toLocaleString("en", {
        maximumFractionDigits: 2,
    });

    return (
        <>
            <PayPalScriptProvider
                options={{
                    "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID,
                    currency: "EUR",
                    "enable-funding": "paylater,venmo,card",
                    components: "buttons,messages",
                    // "disable-funding": "",
                }}
            >
                <section className={styles.common.container}>
                    <h1 className={styles.common.title}>Realizar pago</h1>

                    <div className={`mt-5 border-b pb-5`}>
                        <Loading />

                        <div
                            data-pp-layout="text"
                            data-pp-message
                            data-pp-amount={amount}
                            className="mb-5"
                        />

                        <PayPalButtons
                            style={{
                                color: "blue",
                                shape: "rect",
                                label: "pay",
                                height: 50,
                            }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onCancel={onCancel}
                            onError={onError}
                        />
                    </div>

                    {/*<div className="mt-5 font-montserrat text-xs text-yellow-600 text-right">
                * Por motivos de seguridad solo se acepta el
                pago con cuenta de Paypal.
            </div>*/}

                    <div className="mt-5">
                        <button
                            className={`${styles.common.redButton(false)} w-full`}
                            onClick={() => {
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        checkout: false,
                                    };
                                });
                            }}
                        >
                            <MdCancelPresentation className={`w-4 h-4`} />
                            <span>Cancelar pago</span>
                        </button>
                    </div>
                </section>
            </PayPalScriptProvider>
        </>
    );
};

const CreditCardSubmitPayment = ({ cardHolderName, state, setState, paying, setPaying }) => {
    const hostedFields = usePayPalHostedFields();
    // const { clearCart } = useContext(CartContext);
    const [playPurchaseError] = useSound(purchaseErrorSfx, { volume: 0.05 });
    /*const [playPurchaseSuccess] = useSound(purchaseSuccessSfx, {
        volume: 0.05,
    });*/

    const submitHandler = () => {
        // setPaying(true);

        try {
            if (!hostedFields?.cardFields) {
                console.log("Error en las tarjetas");
                // setPaying(false);
                playPurchaseError();
                return;
            }

            const isFormInvalid =
                Object.values(hostedFields.cardFields.getState().fields).some(
                    field => !field.isValid,
                ) || !cardHolderName?.current?.value;

            if (isFormInvalid) {
                console.log("Los datos del usuario son inválidos");
                // setPaying(false);
                playPurchaseError();
                return;
            }

            // Enviar datos de la tarjeta al servidor
            hostedFields.cardFields
                .submit({
                    cardholderName: cardHolderName.current.value,
                })
                .then(res => {
                    // Obtenemos el resultado de la transacción
                    const { liabilityShift, orderId, authenticationReason, authenticationStatus } =
                        res;

                    console.log({
                        liabilityShift,
                        orderId,
                        authenticationStatus,
                        authenticationReason,
                    });
                })
                .catch(err => {
                    console.error(err);
                    playPurchaseError();
                })
                .finally(() => {} /* setPaying(false)*/);
        } catch (err) {
            console.error(err);
            playPurchaseError();
        }
    };

    return (
        <button
            disabled={paying}
            className={`${styles2.redButton} w-full outline-none font-montserrat`}
            onClick={submitHandler}
        >
            {paying && <AiOutlineLoading className="duration-300 animate-spin" />}
            <span>Realizar el pago</span>
        </button>
    );
};

const CreditCardPayment = ({ state, setState }) => {
    const { cartItems } = useContext(CartContext);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    // const [order, setOrder] = useState(null);
    const [paying, setPaying] = useState(false);
    const cardHolderName = useRef(null);

    /*const [playPurchaseError] = useSound(purchaseErrorSfx, { volume: 0.05 });
    const [playPurchaseSuccess] = useSound(purchaseSuccessSfx, {
        volume: 0.05,
    });*/

    // Obtenemos el token de PayPal
    useEffect(() => {
        setLoading(true);

        try {
            fetch("/.netlify/functions/payment-paypal-create-client-token", {
                method: "POST",
            })
                .then(res => res.json())
                .then(res => {
                    setToken(res?.client_token || res?.clientToken);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    }, []);

    // Efecto de loading mientras se carga PayPalScriptProvider
    const Loading = () => {
        const [{ isPending }] = usePayPalScriptReducer();

        return isPending || loading ? (
            <AiOutlineLoading className="w-8 h-8 animate-spin duration-300 text-gray-500 mb-5" />
        ) : null;
    };

    // Opciones de PayPalScriptProvider
    /*const payPalScriptProviderOptions = {
        currency: "EUR",
        "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID_LLC,
        components: "hosted-fields",
        "data-client-token": token,
        intent: "capture",
        locale: "es_ES",
        vault: false,
    };*/

    if (!token) {
        return null;
    }

    const payPalScriptProviderOptions = {
        clientId: process.env.GATSBY_PAYPAL_CLIENT_ID_LLC,
        dataClientToken: token,
        components: "hosted-fields",
        currency: "EUR",
        locale: "es_ES",
        // intent: "capture",
    };

    return (
        <>
            <section className={`relative ${styles.common.container}`}>
                {(loading || paying) && (
                    <div
                        style={{ backdropFilter: "blur(5px)" }}
                        className="w-full rounded-md top-0 left-0 absolute h-full z-100 bg-black bg-opacity-25 backdrop-blur flex items-center justify-items-center"
                    >
                        <AiOutlineLoading className="w-20 h-20 animate-spin duration-300 text-white block mx-auto my-auto" />
                    </div>
                )}

                <div className="relative z-1">
                    <h1 className={styles.common.title}>Realizar pago</h1>

                    <div className={`mt-5 border-b`}>
                        {token && (
                            <PayPalScriptProvider options={payPalScriptProviderOptions}>
                                <Loading />

                                <PayPalHostedFieldsProvider
                                    notEligibleError="No se puede pagar con PayPal"
                                    styles={{
                                        ".valid": { color: "#28a745" },
                                        ".invalid": { color: "#dc3545" },
                                        input: {
                                            "font-family": "monospace",
                                            "font-size": "16px",
                                        },
                                    }}
                                    createOrder={async () => {
                                        try {
                                            const data = await axios.post(
                                                "/.netlify/functions/payment-paypal-createorder",
                                                JSON.stringify({
                                                    user: state.user,
                                                    amount: state.amount,
                                                    discount: state.discount,
                                                    items: cartItems?.map(({ id, quantity }) => {
                                                        return {
                                                            id,
                                                            quantity,
                                                        };
                                                    }),
                                                }),
                                            );

                                            return data?.data?.orderId;
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    }}
                                >
                                    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
                                        <div className="md:col-span-2 lg:col-span-2">
                                            <label
                                                htmlFor="card-number"
                                                className="text-xs font-montserrat"
                                            >
                                                Número de tarjeta
                                                <span className="text-red-700">*</span>
                                            </label>

                                            <PayPalHostedField
                                                id="card-number"
                                                className="card-field"
                                                style={CUSTOM_FIELD_STYLE}
                                                hostedFieldType="number"
                                                options={{
                                                    selector: "#card-number",
                                                    placeholder: "4111 1111 1111 1111",
                                                }}
                                            />
                                        </div>

                                        <div className="md:col-span-1 lg:col-span-1">
                                            <label
                                                htmlFor="cvv"
                                                className="text-xs font-montserrat"
                                            >
                                                CVV
                                                <span className="text-red-600">*</span>
                                            </label>

                                            <PayPalHostedField
                                                id="cvv"
                                                className="card-field"
                                                style={CUSTOM_FIELD_STYLE}
                                                hostedFieldType="cvv"
                                                options={{
                                                    selector: "#cvv",
                                                    placeholder: "123",
                                                    maskInput: true,
                                                }}
                                            />
                                        </div>

                                        <div className="md:col-span-1 lg:col-span-1">
                                            <label
                                                htmlFor="expiration-date"
                                                className="text-xs font-montserrat"
                                            >
                                                Fecha de expiración
                                                <span className="text-red-600">*</span>
                                            </label>

                                            <PayPalHostedField
                                                id="expiration-date"
                                                className="card-field"
                                                style={CUSTOM_FIELD_STYLE}
                                                hostedFieldType="expirationDate"
                                                options={{
                                                    selector: "#expiration-date",
                                                    placeholder: "MM/YYYY",
                                                }}
                                            />
                                        </div>

                                        <div className="md:col-span-2 lg:col-span-4">
                                            <label
                                                className="text-xs font-montserrat"
                                                htmlFor="card-holder"
                                                title="Esto representa el nombre completo como se muestra en la tarjeta."
                                            >
                                                Nombre del titular de la tarjeta
                                                <span className="text-red-600">*</span>
                                            </label>

                                            <input
                                                id="card-holder"
                                                ref={cardHolderName}
                                                className="card-field block w-full placeholder-gray-800 font-sans outline-none"
                                                style={CUSTOM_FIELD_STYLE}
                                                type="text"
                                                placeholder="Nombre completo"
                                            />
                                        </div>

                                        <div className="md:col-span-2 lg:col-span-4">
                                            <CreditCardSubmitPayment
                                                setPaying={setPaying}
                                                paying={paying}
                                                cardHolderName={cardHolderName}
                                                // state={state}
                                                // setState={setState}
                                            />
                                        </div>

                                        <div className="text-xs text-yellow-600 font-montserrat pb-5 md:col-span-2 lg:col-span-4">
                                            * No guardamos ninguno de los datos de tu tarjeta.
                                        </div>
                                    </div>
                                </PayPalHostedFieldsProvider>
                            </PayPalScriptProvider>
                        )}
                    </div>

                    <div className="mt-5">
                        <button
                            className={`${styles.common.redButton(false)} w-full`}
                            onClick={() => {
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        checkout: false,
                                    };
                                });
                            }}
                        >
                            <MdCancelPresentation className={`w-4 h-4`} />
                            <span>Cancelar pago</span>
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

const CUSTOM_FIELD_STYLE = {
    border: "1px solid #d3d3d3",
    boxShadow: "2px 2px 10px 2px rgba(0,0,0,0.1)",
    borderRadius: "5px",
    height: "40px",
    padding: "5px 10px",
};

const styles2 = {
    redButton: `rounded-full flex items-center space-x-5 text-xs uppercase justify-center  text-cpurple py-3 font-semibold border-4 border-cpurple hover:bg-cpurple duration-300 hover:text-white shadow`,
};

export default PaymentStep;
