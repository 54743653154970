const styles = {
    common: {
        container:
            "rounded shadow-lg p-5 lg:p-10 border border-gray-200 border-opacity-70 relative",
        title: "font-montserrat text-sm font-bold text-gray-500 mb-5 pb-1 uppercase border-b-2 px-2",
        redButton: disabled =>
            `${
                disabled ? "opacity-50 cursor-not-allowed" : ""
            } font-montserrat space-x-5 items-center duration-300 col-span-2 md:col-span-1 flex justify-center bg-cpurple py-3 px-5 text-xs text-white font-semibold font-montserrat uppercase duration-300 rounded shadow-lg bg-opacity-75 flex justify-center items-center`,
        blueButton: disabled =>
            `${
                disabled ? "opacity-50 cursor-not-allowed" : ""
            } font-montserrat space-x-5 items-center duration-300 col-span-2 md:col-span-1 flex justify-center bg-ccyan py-3 px-5 text-xs text-white font-semibold font-montserrat uppercase duration-300 rounded shadow-lg bg-opacity-75 flex justify-center items-center`,
        input: disabled =>
            `font-montserrat text-gray-600 duration-300 tracking-wide py-2 px-4 leading-relaxed appearance-none block w-full bg-gray-100 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500 text-sm ${
                disabled ? "opacity-50 cursor-not-allowed" : ""
            }`,
        overlay:
            "absolute z-20 w-full h-full bg-black bg-opacity-25 opacity-25 rounded top-0 left-0 cursor-not-allowed",
        alert: "font-montserrat text-xs border rounded shadow p-3 mt-10 flex items-center space-x-3",
        alert_success: "border-green-400 text-green-700 bg-green-100",
        alert_fail: "border-red-400 text-red-700 bg-red-100",
        alert_warning: "border-yellow-400 text-yellow-700 bg-yellow-100",
        preJSON: "overflow-y-auto font-montserrat text-sm text-gray-600 text-xs",
    },
    orderSummary: {
        desktop_table: "hidden lg:table table-auto duration-300 w-full rounded-md overflow-hidden",
        mobile_table: "lg:hidden table-auto duration-300 w-full rounded-md overflow-hidden;",
        thead: "border-b-2 border-t-2 bg-gray-100",
        th: "font-montserrat text-sm uppercase py-5 text-gray-600 px-3",
        tfoot: "bg-gray-100",
        tfoot_tr: "border-b-2 border-t-2",
        tbody_tr: "py-3 font-montserrat text-gray-600 text-sm border-b",
        tbody_td: "py-5 px-3",
        tableComments: "text-xs text-right text-gray-500 text-italic font-montserrat mt-5 italic",
    },
};

export default styles;
