import React, { useState, useEffect, useContext } from "react";
import styles from "@components/sections/cesta/Styles";
import { AiOutlineLoading } from "react-icons/ai";
import { toast } from "react-toastify";
import { CartContext } from "@components/contexts/CartContext";
import axios from "axios";
import Marquee from "react-fast-marquee";
import Image from "@components/common/CloudinaryImage";
import useSound from "use-sound";
import addToBasketSfx from "@static/sounds/addProduct.mp3";
import firebase from "gatsby-plugin-firebase";
import { trackFbAddToCart } from "@base/utils/fb";
import { MdAddShoppingCart, MdInfoOutline, MdHourglassEmpty } from "react-icons/md";
import { FiBookOpen, FiPackage, FiSmartphone, FiRefreshCw } from "react-icons/fi";

// import addToBasketSfx from "@static/sounds/addProduct.mp3";
// import removeFromBasketSfx from "@static/sounds/removeProduct.mp3";

const Component = ({ state, setState }) => {
    const { cartItems } = useContext(CartContext);
    const [loading, setLoading] = useState(true);
    const [similar, setSimilar] = useState(null);
    const [play] = useSound(addToBasketSfx, { volume: 0.05 });

    useEffect(() => {
        async function fetchRecommendedProducts() {
            try {
                setLoading(true);
                const query = "/.netlify/functions/recommend_products";
                const items = cartItems.map(item => item.sku);
                const { data } = await axios.post(query, JSON.stringify({ items }));

                setSimilar(data?.similar);
                setLoading(false);
            } catch (err) {
                // toast.error("Error al cargar las recomendaciones.");
            }
        }

        fetchRecommendedProducts();
    }, [cartItems, setSimilar]);

    return (
        <>
            {loading && <AiOutlineLoading className="duration-300 animate-spin" />}

            {!loading && (
                <>
                    {similar?.length > 0 && (
                        <section className={styles.common.container}>
                            <h1 className={styles.common.title}>También te pueden interesar...</h1>

                            <h2 className="font-montserrat text-gray-700 text-sm">
                                Usuarios como tú también han comprado estos productos. Aprovecha
                                ahora y añádelos a tu cesta.
                            </h2>

                            {similar && (
                                <Marquee
                                    autoFill={true}
                                    pauseOnHover={true}
                                    speed={25}
                                    className="mt-5"
                                >
                                    {similar.map(item => {
                                        return <Product item={item} key={item.id} play={play} />;
                                    })}
                                </Marquee>
                            )}
                        </section>
                    )}
                </>
            )}
        </>
    );
};

const Product = ({ item, play }) => {
    const { addProduct } = useContext(CartContext);
    const images = item.images || [];
    const defaultImage = images.length > 0 ? images[0] : "products/common/0";

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <article className="product bg-white product-item mr-5 w-64">
            {/** Información */}
            <div className="product-container">
                <Image alt={item.name} filename={defaultImage} />
                <div className="bg-white p-5 border-t border-gray-200">
                    <h3 title={item.title} className="h3">
                        {item.title}
                    </h3>
                    <div className="mt-5 font-montserrat text-gray-600 text-center">
                        {/** Missatge de descompte */}
                        {item.stock && item.offer && item.offer_text ? (
                            <div className="uppercase shadow bg-red-600 py-2 px-5 rounded text-xs mb-5 text-white">
                                {item.offer_text}
                            </div>
                        ) : null}

                        {item.special_buy ? (
                            <div></div>
                        ) : item.stock ? (
                            item.offer ? (
                                <span className="text-green-500 font-semibold">{item.price} €</span>
                            ) : (
                                <>{!item.split_payment && <span>{item.price} €</span>}</>
                            )
                        ) : (
                            <span className="text-yellow-500">{item.stock_message}</span>
                        )}

                        {item.stock ? (
                            item.offer ? (
                                <span className="line-through ml-2 text-red-500">{item.offer}</span>
                            ) : null
                        ) : null}

                        {item.stock && item.split_payment && (
                            <span className="text-green-600 font-medium">{item.split_payment}</span>
                        )}

                        {/** Mostrasmo aviso de poco stock */}
                        {item.stock &&
                        item.evolmind?.limit > 0 &&
                        (item.evolmind?.enrolled || 1) / (item.evolmind?.limit || 1) > 0.65 ? (
                            <div className="text-red-500 text-sm font-semibold flex items-center space-x-2 justify-center mt-2">
                                <MdHourglassEmpty className="w-5 h-5" />
                                {item.low_stock_message || "¡Últimas plazas disponibles!"}
                            </div>
                        ) : (
                            <></>
                        )}

                        {item.stock && item.recurrent && item.recurrent === "monthly" ? (
                            <div className="inline-block">
                                <span className="text-sm text-orange-700 ml-2">[mensual]</span>
                                <FiRefreshCw className="inline ml-2" />
                            </div>
                        ) : null}

                        {item.stock && item.can_be_sent ? (
                            <span className="text-xs ml-2 text-ccyan">
                                + <FiPackage title="+ Envío" className="inline w-5 h-5" />
                            </span>
                        ) : (
                            ""
                        )}

                        {item.stock && item.app?.modules?.length > 0 && (
                            <span className="text-xs ml-2 text-ccyan">
                                ={" "}
                                <FiBookOpen
                                    title="Incluye libro físico"
                                    className="inline w-5 h-5 ml-2"
                                />
                                {" + "}
                                <FiSmartphone
                                    title="Incluye acceso desde la app"
                                    className="inline w-5 h-5"
                                />
                            </span>
                        )}
                    </div>

                    <div
                        className="absolute z-[1] right-0 bottom-0 font-montserrat text-gray-400 text-right uppercase pr-0 pb-3 w-full"
                        style={{ fontSize: "11px" }}
                    >
                        <div className="flex justify-between items-center px-5">
                            <div></div>
                            <div>Ref: {item.id}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Botones */}
            <div className="grid grid-cols-1 mt-5 gap-2">
                <button
                    title="Comprar producto"
                    className={`product-button flex justify-evenly button-buy`}
                    disabled={!item.stock}
                    onClick={() => {
                        goToTop();
                        addProduct(item);
                        play();

                        // En caso de añadir al carrito
                        (async () => {
                            await trackFbAddToCart({
                                currency: "EUR",
                                value: item.price,
                            });
                        })();

                        firebase.analytics().logEvent("add_to_cart_recommended", {
                            item_id: item.sku,
                            item_name: item.title,
                            item_category: item.type,
                            price: item.price,
                            currency: "EUR",
                            quantity: 1,
                        });

                        toast.info("Producto añadido al carro de la compra.", {
                            position: "bottom-center",
                            closeOnClick: true,
                            pauseOnHover: true,
                            autoClose: 5000,
                        });
                    }}
                >
                    <MdAddShoppingCart
                        className="w-6 h-6 inline"
                        style={{ verticalAlign: "middle" }}
                    />
                </button>

                {item.link && item.more_info ? (
                    <a
                        className="product-button flex justify-evenly"
                        title="Más información"
                        href={item.link}
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        <MdInfoOutline
                            className="w-6 h-6 inline"
                            style={{ verticalAlign: "middle" }}
                        />
                        Más información
                    </a>
                ) : (
                    <div className="opacity-0 h-10">-</div>
                )}
            </div>
        </article>
    );
};

export default Component;
