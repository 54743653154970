import React from "react";
import styles from "@components/sections/cesta/Styles";
import { AiOutlineWarning, AiOutlineRocket, AiOutlineStop } from "react-icons/ai";

const Component = ({ state }) => {
    return (
        <>
            {state.payment && (
                <section className={styles.common.container}>
                    <h1 className={styles.common.title}>Resultado</h1>

                    <div
                        className={`${styles.common.alert} ${
                            state.payment.result === "success"
                                ? styles.common.alert_success
                                : state.payment.result === "warning"
                                  ? styles.common.alert_warning
                                  : styles.common.alert_fail
                        }`}
                    >
                        {state.payment.result === "success" ? (
                            <AiOutlineRocket className="w-5 h-5 flex-shrink-0" />
                        ) : state.payment.result === "warning" ? (
                            <AiOutlineWarning className="w-5 h-5 flex-shrink-0" />
                        ) : (
                            <AiOutlineStop className="w-5 h-5 flex-shrink-0" />
                        )}
                        <span>{state.payment.message}</span>
                    </div>

                    {state.payment.json && process.env.NODE_ENV === "development" && (
                        <div className="mt-5 p-5 rounded border">
                            <pre className={styles.common.preJSON} style={{ maxHeight: "200px" }}>
                                {JSON.stringify(state.payment.json, null, 4)}
                            </pre>
                        </div>
                    )}
                </section>
            )}
        </>
    );
};

export default Component;
