import React, { useState } from "react";
import SmallBanner from "@components/common/SmallBanner";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { FaExclamationTriangle } from "react-icons/fa";
import OrderSummary from "@components/sections/cesta/OrderSummary";
import PaymentStep from "@components/sections/cesta/PaymentStep";
import UserForm from "@components/sections/cesta/UserForm";
import PaymentSelect from "@components/sections/cesta/PaymentSelect";
import ResultStep from "@components/sections/cesta/ResultStep";
import RecommendProducts from "@components/sections/cesta/RecommendProducts";

const Cesta = () => {
    const [state, setState] = useState({
        user: {
            name: "",
            lastname: "",
            email: "",
            emailRepeat: "",
            phone: "",
            phoneRepeat: "",
            address: "",
            city: "",
            postalCode: "",
            province: "",
        },
        amount: {
            subtotal: 0,
            shipping: 0,
            discount: 0,
            tax: 0,
            total: 0,
            units: 0,
        },
        discount: null,
        checkout: false,
        payment: null,
        paymentMethod: null,
        order: null,
    });

    return (
        <>
            <Seo title="Cesta" />
            <Layout>
                <SmallBanner title="Cesta" filename="bgs/bgCart" />
                <div className="container lg:px-0 py-20">
                    {storeEnabled ? (
                        <>
                            <Title className="text-left">Resumen del pedido</Title>
                            <Text className="text-justify">
                                A continuación te mostramos un listado de los productos que tienes
                                en la cesta de la compra, así como sus precios, descuentos y gastos
                                de envío (en caso que el producto sea físico). Recuerda que para
                                realizar el pedido has de rellenar todos los datos que hay en el
                                formulario de <i>Datos del envío</i>.
                            </Text>

                            <div className="mt-10 space-y-10">
                                <OrderSummary state={state} setState={setState} />

                                {process.env.GATSBY_RECOMMEND_ENABLED === "true" && (
                                    <RecommendProducts state={state} set={setState} />
                                )}

                                <UserForm state={state} setState={setState} />

                                {state.checkout && (
                                    <PaymentSelect state={state} setState={setState} />
                                )}

                                {state.checkout && state.paymentMethod && (
                                    <PaymentStep state={state} setState={setState} />
                                )}

                                <ResultStep state={state} />
                            </div>
                        </>
                    ) : (
                        <>
                            <Title className="text-left">
                                <FaExclamationTriangle className="inline mr-5 text-red-700" />
                                El proceso de compra ha sido deshabilitado temporalmente
                            </Title>
                            <Text className="text-justify">
                                <p>
                                    Debido a actualizaciones y mejoras internas que estamos
                                    realizando el proceso de compra ha sido deshabilitado.
                                </p>

                                <p>
                                    En breve estará de vuelta. Disculpa las molestias que te hayamos
                                    podido ocasionar.
                                </p>
                            </Text>
                        </>
                    )}
                </div>
            </Layout>
        </>
    );
};

const storeEnabled = process.env.GATSBY_STORE_ENABLED === "true";

export default Cesta;
