import React from "react";
import styles from "@components/sections/cesta/Styles";
import { FaCreditCard, FaPaypal } from "react-icons/fa";
import { GrRadialSelected } from "react-icons/gr";
// import { MdCancelPresentation } from "react-icons/md";

const Component = ({ state, setState }) => {
    return (
        <section className={styles.common.container}>
            <h1 className={styles.common.title}>Seleccionar método de pago</h1>
            <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-5">
                {availablePaymentMethods
                    ?.filter(m => m.enabled)
                    .map(method => (
                        <div
                            key={method.id}
                            role={"button"}
                            title="Seleccionar método de pago"
                            className={`hover:border-cpurple hover:border-opacity-50 rounded-md border-2 p-5 cursor-pointer duration-300 relative font-montserrat ${
                                state?.paymentMethod === method.id
                                    ? "border-cpurple"
                                    : "border-gray-300"
                            }`}
                            tabIndex={0}
                            onKeyDown={e => {
                                if (e.key === 13) {
                                    setState({
                                        ...state,
                                        paymentMethod: method.id,
                                    });
                                }
                            }}
                            onClick={() => {
                                setState({
                                    ...state,
                                    paymentMethod: method.id,
                                });
                            }}
                        >
                            {/** Popular */}
                            {method.popular && (
                                <span className="absolute top-0 left-0 rounded-full text-white ml-4 -mt-4 px-5 py-1 text-xs uppercase font-semibold bg-cpurple font-montserrat">
                                    Más popular
                                </span>
                            )}

                            {/** Marca Selected */}
                            {state?.paymentMethod === method.id && (
                                <div className="absolute right-0 top-0 h-full flex">
                                    <GrRadialSelected className="w-5 h-5 my-auto ml-auto mr-5" />
                                </div>
                            )}

                            <div className="flex flex-row space-x-5 items-center">
                                <method.icon className=" flex-shrink-0 w-6 h-6 text-blue-800" />
                                <div>
                                    <h1 className="font-semibold text-lg uppercase text-gray-600">
                                        {method.title}
                                    </h1>

                                    <h2 className="text-gray-500 text-sm mr-10 md:mr-0">
                                        {method.description}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>

            {/*<div className="mt-5">
                <button
                    className={`${styles.common.redButton(false)} w-full`}
                    onClick={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                checkout: false,
                            };
                        });
                    }}
                >
                    <MdCancelPresentation className={`w-4 h-4`} />
                    <span>Cancelar pago</span>
                </button>
            </div>*/}
        </section>
    );
};

// Si está activado solo se aplica a un 10% de los usuarios
const creditCardEnabled =
    process.env.GATSBY_STORE_CREDIT_CARD_ENABLED === "true" &&
    (process.env.GATSBY_STORE_CREDIT_CARD_FOR_ALL === "true" || Math.random() <= 1);

const availablePaymentMethods = [
    {
        id: "paypal",
        title: "PayPal",
        description: "Paga de forma segura con tu cuenta de PayPal.",
        enabled: true,
        icon: FaPaypal,
        popular: true,
    },
    {
        id: "card",
        title: "Tarjeta de crédito",
        description: "Paga de forma segura con tu tarjeta de crédito.",
        enabled: creditCardEnabled,
        icon: FaCreditCard,
    },
];

export default Component;
